<template>
  <!-- login -->
  <el-dialog
    @close="dialogClose"
    :visible="isloginVisible"
    width="30%"
    top="10vh"
    :close-on-click-modal="false"
  >
    <div class="loginContent">
      <div class="loginTitle">
        <span class="loginTitleone">图片视频压缩</span>
        <span class="loginTitletwo">专属图片视频压缩神器</span>
      </div>

      <!-- 使用二维码登录 -->
      <div v-show="phoneIsWx === 1" style="position: relative">
        <div class="Overlay" v-if="!agreement">
          <span>请勾选我同意</span>
          <span>《同意协议》、《隐私协议》</span>
        </div>
        <div class="ewm" id="ewm">
          <div class="ewmN">
            <!-- 二维码 -->
            <div class="ewmrq" id="ewmrq"></div>
          </div>
        </div>

      </div>

      <!-- 使用手机号登录 -->

      <div v-show="phoneIsWx === 2">
        <div class="phone">
          <el-form
            class="phoneForm"
            ref="phoneForm"
            :model="phoneLoginForm"
            :rules="phonerules"
          >
            <el-form-item prop="phone">
              <el-input
                v-model="phoneLoginForm.phone"
                placeholder="请输入您的手机号"
              ></el-input>
            </el-form-item>
            <el-form-item prop="verification">
              <div class="yzmcontent">
                <el-input
                  v-model="phoneLoginForm.verification"
                  placeholder="请输入验证码"
                ></el-input>
                <div class="yzmBtn" @click="startCountdown">
                  {{ buttonText }}
                </div>
              </div>
            </el-form-item>

            <el-form-item>
              <el-button style="margin-top:7.5%;margin-bottom:10%" type="primary" @click="loginBtn">立即登录</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>

      <div class="feng">
        <el-divider>一键登录即可使用</el-divider>
      </div>

      <div class="phoneicon">
        <img
          @click="phoneIsWx = 2"
          v-if="phoneIsWx === 1"
          class="phoneiconimg"
          src="../assets/phoneicon.png"
          alt=""
        />
        <img
          @click="phoneIsWx = 1"
          v-else
          class="wxiconimg"
          src="../assets/wxicon.png"
          alt=""
        />
      </div>

      <div class="checkText">
        <el-checkbox v-model="agreement"></el-checkbox>登录即表示同意
        <a class="cs" target="_blank" :href="isconfigforChannel?.standard_conf?.agreement_content?.func.user_agreement ?isconfigforChannel?.standard_conf?.agreement_content?.func.user_agreement :'#' ">用户协议、</a>
        <a class="cs" target="_blank" :href="isconfigforChannel?.standard_conf?.agreement_content?.func.privacy_policy ?isconfigforChannel?.standard_conf?.agreement_content?.func.privacy_policy:'#'">隐私政策</a>
      </div>
    </div>
  </el-dialog>
</template>

<script>
// wx51413c4e4df4c443
//
import assist from '@/utils/commonality'
import { mapActions, mapGetters, mapMutations } from "vuex";
import { verification, login, getUserInfo } from "@/api/login";
import { Base64 } from 'js-base64';
export default {
  mixins: [],
  components: {},
  props: {},
  data() {
    return {
      agreement: false, //协议是否同意
      phoneIsWx: 1, //手机登录还是微信登录 1手机 2微信
      phoneLoginForm: {
        phone: "",
        verification: "",
      },
      phonerules: {
         phone: [
          { required: true, message: '手机号不能为空', trigger: 'blur' },
          { 
            validator: (rule, value, callback) => {
              if (!value) {
                return callback(new Error('手机号不能为空'));
              }
              const phoneRegex = /^1[3-9]\d{9}$/;
              if (!phoneRegex.test(value)) {
                return callback(new Error('请输入正确的手机号'));
              }
              callback();
            }, 
            trigger: 'blur' 
          }
        ],
        verification: [
          { required: true, message: "请输入正确的验证码", trigger: "blur" },
          { min: 6, max: 6, message: "请输入正确的验证码", trigger: "blur" },
        ],
      },
      countdown: 0, //验证码倒计时变量
      timer: null, //清理定时器
    };
  },

  watch: {
    isloginVisible(xin) {
      if (xin) {
            console.log(assist.getCache('configforChannel'));

        this.$nextTick(() => {
          this.getewmContent();
        });
      }
    },
  },
  computed: {
    ...mapGetters(["isloginVisible","isconfigforChannel"]),
    buttonText() {
      return this.countdown > 0
        ? `重发验证码 (${this.countdown})`
        : "发送验证码";
    },
    isDisabled() {
      return this.countdown > 0;
    },
  },
  created() {},
  mounted() {
      

  },

  methods: {
    ...mapActions(["login"]),
    ...mapMutations(["updateloginVisible"]),

    startCountdown() {
      //只验证手机号
      this.$refs.phoneForm.validateField("phone", async (err) => {
        if (err) return;
        if (this.isDisabled) return;
        // let res = await getUserInfo()
        let res = await verification({ tel: this.phoneLoginForm.phone });
        if (res.code !== 200) return;
        this.countdown = 60;
        this.timer = setInterval(() => {
          if (this.countdown > 0) {
            this.countdown--;
          } else {
            clearInterval(this.timer);
          }
        }, 1000);
      });
    },
    dialogClose() {
      //关闭弹出层移除校验结果
      this.agreement = false;
      if (this.phoneIsWx === 2) {
        this.$refs.phoneForm.resetFields();
        this.phoneIsWx = 1;
      }
      this.updateloginVisible(false);
    },
    loginBtn() {
      //登录按钮
      this.$refs.phoneForm.validate(async (valid) => {
        if (valid) {
           if (!this.agreement) {
            this.$tips('请先勾选同意《用户协议》、《隐私协议》')
            // this.$message.error('请先勾选同意《用户协议》、《隐私协议》')
          return
          }
          let res = await this.login({
            code: this.phoneLoginForm.verification,
            tel: this.phoneLoginForm.phone,
             app_id: assist.getAppId(),
                version: assist.getVersion(),
                packet: assist.getPacket(),
                channel_id: assist.getChannelId(),
                other_id: assist.getOtherId(),
                os: assist.getOs(),
          });
          if (res.success) {
            this.updateloginVisible(false);
          }
        } else {
          return false;
        }
      });
    },
    getewmContent() {
      //获取二维码
      (async function () {
        !(function (a, b, c) {
          function d(a) {
            var c = "default";
            a.self_redirect === !0
              ? (c = "true")
              : a.self_redirect === !1 && (c = "false");
            var d = b.createElement("iframe"),
              e =
                "//open.weixin.qq.com/connect/qrconnect?appid=" +
                a.appid +
                "&scope=" +
                a.scope +
                "&redirect_uri=" +
                a.redirect_uri +
                "&state=" +
                a.state +
                "&login_type=jssdk&self_redirect=" +
                c +
                "&styletype=" +
                (a.styletype || "") +
                "&sizetype=" +
                (a.sizetype || "") +
                "&bgcolor=" +
                (a.bgcolor || "") +
                "&rst=" +
                (a.rst || "");
            (e += a.style ? "&style=" + a.style : ""),
              (e += a.href ? "&href=" + a.href : ""),
              (d.src = e),
              (d.frameBorder = "0"),
              (d.allowTransparency = "true"),
              (d.scrolling = "no"),
              (d.width = "300px"),
              (d.height = "400px"),
              (d.sandbox =
                "allow-scripts allow-top-navigation allow-same-origin");
            var f = b.getElementById(a.id);
            (f.innerHTML = ""), f.appendChild(d);
          }
          a.WxLogin = d;
        })(window, document);
      })();


let locationHref = assist.setUrl(location.href, {
    app_id: assist.getAppId(), platform_id: assist.getPlatform(), channel_id: assist.getChannelId(), packet: assist.getPacket(),
});
let sucessHref = assist.normalizeUrl(locationHref)
console.log(sucessHref);
//请求二维码，当用户扫描完二维码，跳转到对应的页面(home) 
let appId = 'wx151e8f1a295abd77';
// wx151e8f1a295abd77
      new WxLogin({
        id: "ewm",
        appid: appId,
        scope: "snsapi_login",
        redirect_uri:encodeURIComponent(`https://auth.server.sanwubeixin.cn/callback/wechat/login?redirect_uri=${Base64.encode(sucessHref)}`),
        state: "STATE",
        href: "",
      });
    },
  },
};
</script>

<style scoped lang="scss">
// login
::v-deep .el-dialog {
  border-radius: 20px !important;
}
::v-deep .el-dialog__headerbtn {
  font-size: 25px !important;
  font-weight: 800 !important;
  color: #000 !important;
}
::v-deep .el-dialog__body {
  padding: 0 0 15px 0 !important;
}

.phone { 
  margin-top:15%;
}
.loginContent {
  width: 100%;
  min-height: 73.5vh;
  .loginTitle {
    display: flex;
    flex-direction: column;
    text-align: center;

    .loginTitleone {
      font-size: 30px;
      color: #333333;
      font-weight: 500;
    }
    .loginTitletwo {
      font-size: 14px;
      color: #868686;
      margin: 10px 0;
    }
  }
  .Overlay {
    position: absolute;
    top: 14%;
    left: 26%;
    width: 48.3%;
    height: 65%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    z-index: 9999;
  }
  .ewm {
    width: 266px;
    height: 380px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .ewmN {
      position: relative;
      width: 232px;
      height: 232px;
    }
  }

  .phoneForm {
    width: 90%;
    margin: 0 auto;
    ::v-deep .el-input__inner {
      border: 2px solid #767676;
      border-radius: 10px;
      height: 60px;
    }

    .yzmcontent {
      display: flex;
    }
    .yzmBtn {
      width: 85%;
      height: 60px;
      border: 2px solid #ededed;
      border-radius: 16px;
      margin-left: 10px;
      text-align: center;
      line-height: 60px;
      color: #ff6b40;
      cursor: pointer;
      font-size: 14px;
    }

    ::v-deep .el-button {
      width: 100% !important;
      height: 60px !important;
      font-size: 20px;
      background: linear-gradient(
        to right,
        #ffbb57 0%,
        #fa6685 50%,
        #a865d4 100%
      );
      border: none;
    }
  }

  .tsText {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .feng {
    width: 90%;
    margin: 0 auto;
    ::v-deep .el-divider__text {
      font-weight: 600;
      font-size: 18px;
    }
  }

  .phoneicon {
    width: 100%;
    display: flex;
    justify-content: center;
    .phoneiconimg {
      width: 30px;
      height: 47px;
    }
    .wxiconimg {
      width: 47px;
      height: 47px;
    }
  }

  .checkText {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .cs {
      color: #ff6f40;
    }
    ::v-deep .el-checkbox {
      margin-right: 10px !important;
    }
  }
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background: linear-gradient(to bottom right, #FFBB57, #FA6685, #A865D4);
  border-color:#ffffff ;
}
</style>
